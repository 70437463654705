html {
  font-size: 16px;

  .noscrollbar {
    box-sizing: border-box;
    scrollbar-color: transparent transparent; /* thumb and track color */
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0 !important;
      display: none;
    }

    &::-webkit-scrollbar-button {
      width: 0 !important;
      height: 0 !important;
    }

    ::-webkit-scrollbar-track {
      width: 0 !important;
      height: 0 !important;
      background: transparent;
    }

    ::-webkit-scrollbar-track-piece {
      width: 0 !important;
      height: 0 !important;
    }

    ::-webkit-scrollbar-thumb {
      background: transparent;
      border: none;
    }

    ::-webkit-scrollbar-corner {
      width: 0 !important;
      height: 0 !important;
    }

    ::-webkit-resizer {
      width: 0 !important;
      height: 0 !important;
    }
  }
}

.quill {
  .ql-container {
    font-size: 15px;
    color: rgb(17, 24, 39);
  }

  > .ql-toolbar.ql-snow {
    border-radius: 5px 5px 0 0;
    border-color: rgb(209, 213, 219);
  }

  > .ql-container.ql-snow {
    border-radius: 0 0 5px 5px;
    border-color: rgb(209, 213, 219);
  }
}

.MuiFormControl-root {
  &.MuiTextField-root {
    .MuiOutlinedInput-root {
      border-radius: 6px;

      input {
        padding: 8px 10px;
        color: rgb(17, 24, 39);
        font-size: 14px;
      }

      button {
        font-size: 14px;
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: rgb(209, 213, 219) !important;
        padding: 0 4px !important;
      }
    }
  }
}
