.container {
  h3 {
    margin-bottom: 0.5rem;
  }

  ul {
    padding-left: 1rem;
    margin-top: 0;

    li {
      div {
        font-size: 0.9rem;
        margin-left: 0;

        p {
          margin: 0;
        }
      }
    }
  }
}
