.container {
  .sidebar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0.5rem;
    background-color: #fff;
    max-width: 1350px;
    margin: 2rem auto;

    a {
      text-decoration: none;
    }

    > a:first-child {
      margin-bottom: 2rem;
    }

    .logo {
      margin-left: 0.3rem;
    }

    .closeButton {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 2px solid #212121;
      height: 2rem;
      width: 2rem;

      &:hover {
        background-color: #f5f5f5;
        cursor: pointer;
      }
    }
  }

  .main {
    max-width: 1080px;
    margin: 0 auto 6rem auto;
  }
}
