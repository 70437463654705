.container {
  .input {
    width: 30rem;
    margin-top: 1rem;
  }
}

.resultsContainer {
  background-color: #fff;
  margin-top: 0.2rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  z-index: 999;
  max-height: 25rem;
  overflow-y: auto;

  &:empty {
    box-shadow: none;
    margin: 0;
    padding: 0;
  }

  .investmentEntity {
    display: flex;
    flex-flow: column nowrap;
    border-bottom: 1px solid #eee;
    padding: 0 0.5rem 0.5rem 0.5rem;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: #fafafa;
      cursor: pointer;
    }

    .name {
      font-weight: 500;
      margin: 0.2rem 0;
    }
  }
}
