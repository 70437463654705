.title {
  font-size: 3rem;
  color: #fff;
  font-weight: 600;
}

.childrenContainer {
  padding: 1rem;
  width: 100%;
}

.logoContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;

  .logo {
    margin: 1.5rem 1.5rem;
    height: 1rem;

    &:hover {
      cursor: pointer;
    }
  }
}
