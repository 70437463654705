.container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .image {
    height: 25rem;
  }

  .title {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .description {
    margin-top: 0;
    text-align: center;
  }
}
