$border-color: #eee;

.bulkActionsContainer {
  position: fixed;
  bottom: 6rem;
  left: calc(40%);
  background: #212121;
  min-width: 25rem;
  border-radius: 0.5rem;
  z-index: 9;
  padding: 0.5rem 1rem;

  .top {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .actionLabel {
      font-weight: 600;
      font-size: 0.9rem;
      color: #fff;
    }
  }

  .bottom {
    margin-top: 0.5rem;
  }
}

.paginationContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin: 1rem 0;
}
